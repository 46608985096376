import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import SendSms from '../Index/MainScreen/Desktop/SendSms'
import './E404.scss'

interface Props {
  pokerLink?: Record<'android' | 'ios' | 'web', string>
  rummyLink?: Record<'android' | 'ios' | 'web', string>
  lang?: string
}

const E404: React.FC<Props> = ({ lang, pokerLink, rummyLink }) => {
  return (
    <div id="E404">
      <div className="error_block">
        <Container>
          <div className="error_area">
            <h1>Oops! We couldn’t find that page.</h1>
            <div className="image">
              <StaticImage
                loading="eager"
                src="../../images/404-mascot.png"
                alt="error"
                width={350}
              />
            </div>
            <p className="d-block d-md-none">Download Rummy & Poker App</p>
            <p className="d-none d-md-block">
              Don’t worry! Play Rummy & Poker On Web
            </p>
            <Row>
              <Col className="game-col">
                <div className="game-info">
                  <StaticImage
                    src="../../images/thumbnails/Verticle_TexasHoldem.png"
                    alt="poker"
                    width={54}
                  />
                  <span>{lang === 'hindi' ? 'पोकर' : 'Poker'}</span>
                </div>
                <div className="game-form">
                  <SendSms
                    smsLink={pokerLink}
                    game="poker"
                    downloadBtnText={
                      lang === 'hindi' ? 'पोकर डाउनलोड करें' : 'Download Poker'
                    }
                    lang={lang}
                  />
                </div>
              </Col>
              <Col className="game-col">
                <div className="game-info">
                  <StaticImage
                    src="../../images/thumbnails/Rummy-Thumbnails.png"
                    alt="rummy"
                    width={54}
                  />
                  <span>{lang === 'hindi' ? 'रमी' : 'Rummy'}</span>
                </div>
                <div className="game-form">
                  <SendSms
                    smsLink={rummyLink}
                    game="rummy"
                    downloadBtnText={
                      lang === 'hindi' ? 'रमी डाउनलोड करें' : 'Download Rummy'
                    }
                    lang={lang}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default E404
