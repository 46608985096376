import React from 'react'
import E404 from '../components/E404/E404'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  pokerOneLinksHomePage,
  rummyOneLinksHomePage,
} from '../components/one-links'

const NotFoundPage: React.FC = () => (
  <Layout
    pokerOneLink={pokerOneLinksHomePage}
    rummyOneLink={rummyOneLinksHomePage}
  >
    <SEO title="404: Not found" />
    <E404 rummyLink={rummyOneLinksHomePage} pokerLink={pokerOneLinksHomePage} />
  </Layout>
)

export default NotFoundPage
