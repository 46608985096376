/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

interface Props {
  description?: string
  lang?: string
  meta?: {
    name: string
    content: string
  }[]
  title: string
  url?: string
  breadcrumbsSchema?: unknown
  faqSchema?: unknown
  reviewSchemaRummyAndroid?: unknown
  reviewSchemaRummyIos?: unknown
  reviewSchemaPokerAndroid?: unknown
  reviewSchemaPokerIos?: unknown
  blogPostingSchema?: unknown
  organizationSchema?: unknown
  contactSchema?: unknown
  videoSchema?: unknown[]
  productSchema?: unknown
  webPageSchema?: unknown
  websiteSchema?: unknown
  personSchema?: unknown
  newsSchema?: unknown
  hideHrefLang?: boolean
}

const SEO: React.FC<Props> = ({
  description,
  lang,
  meta = [],
  title,
  url,
  breadcrumbsSchema,
  faqSchema,
  reviewSchemaRummyAndroid,
  reviewSchemaRummyIos,
  reviewSchemaPokerAndroid,
  reviewSchemaPokerIos,
  blogPostingSchema,
  productSchema,
  organizationSchema,
  contactSchema,
  videoSchema = [],
  webPageSchema,
  websiteSchema,
  personSchema,
  newsSchema,
  hideHrefLang,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const SITE_URL = site.siteMetadata.siteUrl
  const location = useLocation()
  const schemaList = [
    organizationSchema,
    websiteSchema,
    breadcrumbsSchema,
    faqSchema,
    reviewSchemaRummyAndroid,
    reviewSchemaRummyIos,
    reviewSchemaPokerAndroid,
    reviewSchemaPokerIos,
    blogPostingSchema,
    contactSchema,
    productSchema,
    personSchema,
    newsSchema,
    webPageSchema,
    ...videoSchema,
  ]

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate="%s"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=2.0',
        },
        {
          name: 'facebook-domain-verification',
          content: '6twi0m7r1deq0sfqfks98lb4biiiec',
        },
      ].concat(meta)}
    >
      {schemaList
        .filter(s => s)
        .map((s, idx) => {
          const str = JSON.stringify(s)
          return (
            <script type="application/ld+json" key={idx} defer>
              {str}
            </script>
          )
        })}
      {!hideHrefLang && (
        <>
          <link
            rel="alternate"
            href={`${SITE_URL}${location.pathname}`}
            hrefLang="en-in"
          />
          <link
            rel="alternate"
            href={`${SITE_URL}${location.pathname}`}
            hrefLang="x-default"
          />
        </>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
